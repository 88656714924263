@import 'vue3-carousel/dist/carousel.css';
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

a, input, button, span, div, p, h1, h2, h3, h4, h5, h6, label, legend, select, textarea {
    @apply ring-slate-200 !important;
    @apply focus:ring-slate-200 !important;
    @apply focus-visible:ring-slate-200 !important;
}

.hamburger-menu {
    @apply w-[200px] absolute right-0 mt-8 p-4 mr-7;
    z-index: 1000;
}

header {
    @apply shadow-md p-4 bg-black text-white;
    @apply h-[130px] sm:left-0;
}

footer {
    @apply p-4;
}

main {
    @apply p-2 m-4 bg-white text-black min-h-screen ring-transparent;
}

nav ul {
    @apply justify-end flex space-x-6;
    font-family: Roboto, "Noto Sans", sans-serif;
    font-weight: 600;
    font-style: italic;
    font-size: 1.5em;
}

h1 {
    font-weight: 900;
    font-style: italic;
    @apply text-4xl;
}

h2 {
    font-weight: 700;
    @apply text-3xl;
    font-family: Roboto, "Noto Sans", sans-serif;
}


h3 {
    font-weight: 700;
    @apply text-2xl;
}

h4 {
    font-weight: 700;
    @apply text-lg;
}

a.highlight {
    color: #bed62f;
}

a:hover {
    color: #bed62f;
    @apply underline;
}

#bodyLinks {
    @apply grid grid-cols-12 gap-4 text-center mt-4;
}

.homepage-carousels {
    @apply grid xs:grid-cols-4 sm:grid-cols-12;
}

.carousel-container {
    @apply col-span-4 mt-3 text-center mb-[3rem];
}

.carousel__viewport {
    @apply h-[300px] sm:h-[400px] lg:h-[500px];
}

.body-link-container {
    @apply col-span-12 text-center m-6;
}

.body-link-button {
    font-weight: 900;
    font-style: italic;
    @apply sm:text-4xl text-center text-black bg-white border-4 border-black sm:p-3 cursor-pointer;
    @apply xs:text-[1.75rem] xs:p-3;
}

.body-link-button-disabled {
    font-weight: 900;
    font-style: italic;
    @apply xs:text-[2rem] border-[#969696] text-gray-600 sm:text-4xl text-center text-[#969696] bg-white border-4 p-3;
}

.disabled-badge {
    line-height: .5rem;
    padding: .5rem;
    @apply absolute top-1 left-1 rounded-md bg-gray-50 px-2 py-1 text-[.5rem] font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10;
}

.body-link-button-age-verification {
    font-weight: 900;
    font-style: italic;
    @apply text-4xl text-center text-black bg-white border-4 border-black p-2 cursor-pointer;
}

.body-help-button {
    font-weight: 900;
    font-style: italic;
    @apply text-2xl text-center bg-white border-4 border-black p-4 fixed right-4 bottom-16 bg-black text-white p-2 rounded-full shadow-lg;
}

.footer-link-button {
    font-weight: 900;
    font-style: italic;
    @apply text-sm text-center text-white bg-black border-4 border-white p-[.25em];
}

.store-filter, .strain-filter {
    @apply p-4;
}

.store-filter legend, .strain-filter legend {
    @apply text-2xl font-bold pt-3;
}

.masthead-logo-black {
  background-image: url('/resources/images/logo.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  width: auto;
  height: 100px;
}

.masthead-logo-white {
  background-image: url('/resources/images/logo-white.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: auto;
  height: 100px;
  width: 300px;
  margin-bottom: -30px;
}

/** CAROUSEL STYLES **/
.carousel {
    @apply pb-6;
}

.blockquote {
    border-left: .5rem solid black;
    @apply pl-2;
    max-width: 600px;
    margin: auto;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 34px;
}

.carousel__item {
    min-height: 200px;
    width: 100%;
    font-size: 20px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    }

.carousel__slide {
}

    .carousel__prev,
    .carousel__next {
    box-sizing: content-box;
}

/** WEED LIST STYLES **/
.weedList__item__image img {
    @apply xs:h-[445px] sm:h-[575px] w-auto rounded-tl-2xl rounded-br-2xl object-cover;
}

.weedList__item__image, .weedList__item__info {
    @apply xs:min-h-[445px] sm:min-h-[575px];
}

.weedList__item__action {
    @apply cursor-pointer inline-block px-4 py-2 text-black font-bold w-full text-center;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}

.weedList__item__drawer {
    @apply hidden bg-white text-black transition-all duration-300 ease-in-out;
}

.weedList__item__drawer--open {
    @apply block;
}

.weedList {
    @apply grid grid-cols-12 gap-4;
}

.weedList li {
    @apply xs:col-span-12 sm:col-span-4 sm:p-4 sm:m-4 bg-white text-black text-left;
}

.dromap {
    width: 100%; 
    height: calc(100vh - 350px);
}

/** AGE VERIFICATION MODAL **/
/* Overlay */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

/* Modal */
.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    @apply xs:p-[25px] sm:p-[50px];
    border-radius: 10px;
    z-index: 1000;
}

/* Modal */
.help-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 65%;
    overflow-y: scroll;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 50px;
    border-radius: 10px;
    z-index: 1000;
}


/* Close button */
.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    width: 30px;
}

/* GRecaptcha */
.grecaptcha-badge {
    display: none !important;
    width: 70px !important;
    overflow: hidden !important;
    transition: all 0.3s ease !important;
    left: 4px !important;
  }
  
  .grecaptcha-badge:hover {
    display:none;
    width: 256px !important;
  }


  /* CARD FLIPPER */
  .flip-container {
    perspective: 1000px;
    @apply xs:min-h-[475px] sm:min-h-[575px] xs:p-0 xs:m-0 sm:p-4 sm:m-4;
  }
  
  .flip-container:hover .flipper, .flip-container.hover .flipper {
    transform: rotateY(180deg);
  }
    
  .flipper {
    transition: 0.6s;
    transform-style: preserve-3d;
    position: relative;
  }
  
  .front, .back {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .front {
    width: 100%;
    z-index: 2;
    transform: rotateY(0deg);
  }
  
  .front img {
    width: 100%;
    object-fit: cover; /* Ensures the image covers the container without stretching */
    object-position: center;
  }

  .back {
    transform: rotateY(180deg);
  }
